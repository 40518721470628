import { Col, Modal, Row, Table } from "react-bootstrap";
import { formatWithDots } from "../../../../utils/formatNumbers";
import { Link } from "react-router-dom";
import { ArrowLeft, PencilSquare } from "react-bootstrap-icons";
import { formatDateTimeToDDMMYY } from "../../../../utils/formatDateTime";
import { UpdatePaymentModal } from "../../../../components/modals/updatePaymentModal";
import { useState } from "react";

export const PaymentsTableModal = ({ title, payments, showPaymentsTableModal, setShowPaymentsTableModal, refetch }) => {
    const [selectedPaymentId, setSelectedPaymentId] = useState("0");
    const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
    const paymentTypeMap = {
        cash: "Bar",
        paypal: "PayPal",
        banktransfer: "Überweisung",
        card: "Kreditkarte",
    };

    return (
        <>
            {payments.length > 0 && (
                <Modal
                    show={showPaymentsTableModal}
                    onHide={() => setShowPaymentsTableModal(false)}
                    centered
                    fullscreen
                >
                    <Modal.Header className="w-100">
                        <Row className="w-100">
                            <Col xs={1}>
                                <ArrowLeft
                                    width={24}
                                    height={24}
                                    onClick={() => setShowPaymentsTableModal(false)}
                                    style={{ cursor: "pointer", marginTop: "10px" }}
                                />
                            </Col>
                            <Col xs={10} className="text-center">
                                <Modal.Title>{title}</Modal.Title>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </Modal.Header>

                    <Modal.Body>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Benutzer</th>
                                    <th>Grund</th>
                                    <th>Erstellt</th>
                                    <th>Bezahlt am</th>
                                    <th>Zahlungsart</th>
                                    <th>Betrag</th>
                                    <th>Aktionen</th>
                                </tr>
                            </thead>

                            <tbody>
                                {payments.map((payment, index) => {
                                    return (
                                        <tr key={payment.id}>
                                            <th>{index + 1}</th>
                                            <th>
                                                <Link to={`/user/${payment.user.id}`}>
                                                    {payment.user.firstName} {payment.user.lastName}
                                                </Link>
                                            </th>
                                            <th>
                                                {payment.enrollment ? (
                                                    <Link to={`/course/${payment.enrollment.course.id}`}>
                                                        {payment.reason}
                                                    </Link>
                                                ) : payment.subscription ? (
                                                    <Link
                                                        to={`/subscription/${payment.subscription.subscriptionModel.id}`}
                                                    >
                                                        {payment.reason}
                                                    </Link>
                                                ) : (
                                                    <>{payment.reason}</>
                                                )}
                                            </th>
                                            <th>
                                                {formatDateTimeToDDMMYY(payment.date)}
                                            </th>
                                            <th>
                                                {payment.status == "paid"
                                                    ? formatDateTimeToDDMMYY(payment.paidAt)
                                                    : ""}
                                            </th>
                                            <th>
                                                {payment.status == "paid"
                                                    ? `${paymentTypeMap[payment.paymentType]}`
                                                    : ""}
                                            </th>
                                            <th>{formatWithDots(payment.amount)} €</th>
                                            <th>
                                                {!payment.invoiceId ? (
                                                    <PencilSquare
                                                        style={{ cursor: "pointer" }}
                                                        title="Zahlung bearbeiten"
                                                        onClick={() => {
                                                            setSelectedPaymentId(payment.id);
                                                            setShowUpdatePaymentModal(true);
                                                        }}
                                                    />
                                                ) : (
                                                    <p className="m-0">Verbuchte Zahlungen können nicht bearbeiten werden.</p>
                                                )}
                                            </th>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Modal.Body>

                    <UpdatePaymentModal
                        showUpdatePaymentModal={showUpdatePaymentModal}
                        setShowUpdatePaymentModal={setShowUpdatePaymentModal}
                        paymentId={selectedPaymentId}
                        refetch={refetch}
                    />
                </Modal>
            )}
        </>
    );
};
