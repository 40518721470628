export function formatWithDots(input) {
    if (input !== undefined && input !== null) {
        // Convert input to a number and ensure two decimal places
        const number = parseFloat(input).toFixed(2);

        // Replace dot with comma for decimal separator
        const formattedNumber = number.replace('.', ',');

        // Add dot as thousand separator
        return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return '0,00';
}