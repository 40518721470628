import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Loader } from "../loader";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../utils/apiRequest";
import { useEffect, useState } from "react";
import { formatDateAndTimeToISO, formatDateTimeToYYYYMMDD } from "../../utils/formatDateTime";
import { useOutletContext } from "react-router-dom";
import { Trash } from "react-bootstrap-icons";

export const UpdatePaymentModal = ({ showUpdatePaymentModal, setShowUpdatePaymentModal, paymentId = "0", refetch }) => {
    const { token } = useRecoilValue(userState);
    const paymentDataQuery = useQuery({
        queryKey: ["update-payment", paymentId],
        queryFn: () => getRequest(`/auth/payment/${paymentId}`, token),
        enabled: paymentId !== "0",
    });
    const { data } = paymentDataQuery;

    const [showToast, setShowToast] = useOutletContext();
    const [paymentData, setPaymentData] = useState({});
    const [alreadyPaid, setAlreadyPaid] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (data) {
            setPaymentData(data);
            if (data.status == "paid") {
                setAlreadyPaid(true);
            }
        }
    }, [data]);

    useEffect(() => {
        if (!paymentData?.paidAt) {
            setPaymentData((prev) => {
                return {
                    ...prev,
                    paidAt: new Date(),
                };
            });
        }
    }, [paymentData]);

    useEffect(() => {
        setPaymentData((prev) => {
            return {
                ...prev,
                status: alreadyPaid ? "paid" : "unpaid",
            };
        });
    }, [alreadyPaid]);

    const handleSubmit = async () => {
        if (paymentData.amount === "" || paymentData.reason === "") {
            setError(true);
            return;
        }

        /*  Deleting data from payment object, because otherwise Prisma annoys me, that it can't handle
            it even though it just returned it like this. wtf.
        */
        delete paymentData.userId;
        delete paymentData.invoiceId;
        delete paymentData.user;

        const response = await postRequest("/auth/payment/update", token, paymentData);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: "Fehler beim Updaten der Zahlung",
                toastAppearance: "danger",
            });
        } else {
            setShowToast({
                toastStatus: true,
                toastMessage: "Zahlung erfolgreich gespeichert. 💵",
                toastAppearance: "success",
            });
            setShowUpdatePaymentModal(false);
        }

        if (refetch) {
            refetch();
        }
    };

    const handleDelete = async (id) => {
        const response = await postRequest("/auth/payment/delete", token, { id: id });
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: "Fehler beim Löschen der Zahlung",
                toastAppearance: "danger",
            });
        } else {
            setShowToast({
                toastStatus: true,
                toastMessage: "Zahlung erfolgreich gelöscht. 💵",
                toastAppearance: "success",
            });
            setShowUpdatePaymentModal(false);
        }

        if (refetch) {
            refetch();
        }
    };

    return (
        <Loader isActive={paymentDataQuery.isFetching || paymentDataQuery.isLoading}>
            {data && (
                <Modal
                    show={showUpdatePaymentModal}
                    onHide={() => {
                        setShowUpdatePaymentModal(false);
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Zahlung bearbeiten</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={6}>Benutzer:</Col>
                            <Col xs={6}>{`${data?.user?.firstName} ${data?.user?.lastName}`}</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Zahlungsgrund:</Col>
                            <Col xs={6}>
                                <Form.Control
                                    type="text"
                                    className="text-end"
                                    value={paymentData?.reason}
                                    onChange={(e) => setPaymentData((prev) => ({ ...prev, reason: e.target.value }))}
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Teilnehmer:</Col>
                            <Col xs={6}>
                                <Form.Control
                                    type="text"
                                    className="text-end"
                                    value={paymentData?.participant}
                                    onChange={(e) =>
                                        setPaymentData((prev) => ({ ...prev, participant: e.target.value }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Betrag:</Col>
                            <Col xs={6}>
                                <Form.Control
                                    type="text"
                                    placeholder="€"
                                    className="text-end"
                                    value={paymentData?.amount}
                                    onChange={(e) => setPaymentData((prev) => ({ ...prev, amount: e.target.value }))}
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Datum:</Col>
                            <Col xs={6}>
                                <Form.Control
                                    type="date"
                                    value={formatDateTimeToYYYYMMDD(paymentData?.date)}
                                    onChange={(e) =>
                                        setPaymentData((prev) => ({
                                            ...prev,
                                            date: formatDateAndTimeToISO(e.target.value),
                                        }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Bereits bezahlt?</Col>
                            <Col xs={6}>
                                <Form.Check
                                    checked={alreadyPaid}
                                    onChange={() => setAlreadyPaid((prev) => !prev)}
                                ></Form.Check>
                            </Col>
                        </Row>
                        {alreadyPaid && (
                            <>
                                <Row className="mt-3">
                                    <Col xs={6}>Bezahlt am:</Col>
                                    <Col xs={6}>
                                        <Form.Control
                                            type="date"
                                            value={formatDateTimeToYYYYMMDD(
                                                paymentData?.paidAt ? paymentData?.paidAt : new Date()
                                            )}
                                            onChange={(e) =>
                                                setPaymentData((prev) => ({
                                                    ...prev,
                                                    paidAt: formatDateAndTimeToISO(e.target.value),
                                                }))
                                            }
                                        ></Form.Control>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col xs={6}>Zahlungsart:</Col>
                                    <Col xs={6}>
                                        <Form.Select
                                            value={paymentData.paymentType}
                                            onChange={(e) =>
                                                setPaymentData((prev) => ({ ...prev, paymentType: e.target.value }))
                                            }
                                        >
                                            <option value={"cash"}>Bar</option>
                                            <option value={"paypal"}>PayPal</option>
                                            <option value={"banktransfer"}>Überweisung</option>
                                            <option value={"card"}>Karte</option>
                                        </Form.Select>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col xs={6}>Transaktionsgebühr:</Col>
                                    <Col xs={6}>
                                        <Form.Control
                                            className="text-end"
                                            type="text"
                                            placeholder="€"
                                            value={paymentData.transactionFee}
                                            onChange={(e) =>
                                                setPaymentData((prev) => ({ ...prev, transactionFee: e.target.value }))
                                            }
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {error && (
                            <Row>
                                <Col xs={12}>
                                    <Alert className="mt-3 text-center" variant="danger">
                                        <p className="m-0">Nicht alle notwendigen Felder ausgefüllt</p>
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className="w-100">
                            <Col xs={3} className="ps-0">
                                <Trash
                                    title="Zahlung löschen"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleDelete(paymentData.id)}
                                />
                            </Col>
                            <Col xs={9} className="text-end">
                                <Button onClick={() => setShowUpdatePaymentModal(false)} variant="danger">
                                    Abbrechen
                                </Button>
                                <Button variant="success" className="ms-3" onClick={handleSubmit}>
                                    Speichern
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            )}
        </Loader>
    );
};
