import { Col, Container, Form, InputGroup, Row, Stack } from "react-bootstrap";
import { Loader } from "../../components/loader";
import { TitleWithBackButton } from "../../components/title-with-back-button/titleWithBackButton";
import { useEffect, useState } from "react";
import { quarterMap, getMonthMap } from "../../utils/formatDateTime";
import { UnpaidPayments } from "./unpaid-payments-card/UnpaidPayments";
import { PaidPaymentsNoInvoice } from "./paid-payments-card/PaidPaymentsNoInvoice";
import { PaidPaymentsInvoice } from "./paid-payments-card/PaidPaymentsInvoice";
import { TaxCard } from "./tax-card/TaxCard";
import { CourseStatsCard } from "./course-stats-card/CourseStatsCard";
import { CourseStatsCardFuture } from "./course-stats-card/CourseStatsCardFuture";
import { ActiveSubsCard } from "./sub-stats-card/active-subs-card/ActiveSubsCard";
import { TaxCardUnpaid } from "./tax-card/TaxCardUnpaid";
import { useIsMobile } from "../../utils/hooks/isMobile";
import { SubIncomeCard } from "./sub-stats-card/active-subs-card/SubIncomeCard.jsx/SubIncomeCard";

export const Statistics = () => {
    const [startDate, setStartDate] = useState(`${new Date().getFullYear()}-01-01`);
    const [endDate, setEndDate] = useState(`${new Date().getFullYear()}-12-31`);
    const [year, setYear] = useState(new Date().getFullYear());
    const [quarter, setQuarter] = useState(0);
    const [month, setMonth] = useState(0);

    const isMobile = useIsMobile();

    useEffect(() => {
        if (quarter > 0 && month !== 0) {
            setMonth(0);
        }
        if (quarter > 0) {
            setStartDate(`${year}-${quarterMap[quarter].start}`);
            setEndDate(`${year}-${quarterMap[quarter].end}`);
        } else if (month > 0) {
            setStartDate(`${year}-${getMonthMap(year)[month].start}`);
            setEndDate(`${year}-${getMonthMap(year)[month].end}`);
        } else {
            setStartDate(`${year}-01-01`);
            setEndDate(`${year}-12-31`);
        }
    }, [year, quarter]);

    useEffect(() => {
        if (month > 0 && quarter !== 0) {
            setQuarter(0);
        }
        if (month > 0) {
            setStartDate(`${year}-${getMonthMap(year)[month].start}`);
            setEndDate(`${year}-${getMonthMap(year)[month].end}`);
        } else if (month === 0 && quarter === 0) {
            setStartDate(`${year}-01-01`);
            setEndDate(`${year}-12-31`);
        }
    }, [month]);

    return (
        <Loader isActive={false}>
            <Container fluid className="mb-0">
                <TitleWithBackButton title={"Statistiken"} />
            </Container>

            <Container className="mt-4 bg-dark-subtle p-3 rounded">
                <Row className="">
                    <Col xs={12} md={6} className={`${isMobile ? 'mb-3' : ''}`}>
                        <InputGroup>
                            <InputGroup.Text>Von:</InputGroup.Text>
                            <Form.Control
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            ></Form.Control>
                        </InputGroup>
                    </Col>

                    <Col xs={12} md={6}>
                        <InputGroup>
                            <InputGroup.Text>Bis:</InputGroup.Text>
                            <Form.Control
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            ></Form.Control>
                        </InputGroup>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col xs={12} md={4} className={`${isMobile ? 'mb-3' : ''}`} >
                        <Form.Select value={year} onChange={(e) => setYear(e.target.value)}>
                            <option value={new Date().getFullYear()}>Aktuelles Jahr</option>
                            <option value={new Date().getFullYear() - 1}>Vorjahr</option>
                        </Form.Select>
                    </Col>

                    <Col xs={12} md={4}>
                        <Form.Select value={quarter} onChange={(e) => setQuarter(e.target.value)}>
                            <option value={0}>Quartal</option>
                            <option value={1}>1. Quartal</option>
                            <option value={2}>2. Quartal</option>
                            <option value={3}>3. Quartal</option>
                            <option value={4}>4. Quartal</option>
                        </Form.Select>
                    </Col>

                    <Col xs={12} md={4} className={`${isMobile ? 'mt-3' : ''}`} >
                        <Form.Select value={month} onChange={(e) => setMonth(e.target.value)}>
                            <option value={0}>Monat</option>
                            <option value={1}>Januar</option>
                            <option value={2}>Februar</option>
                            <option value={3}>März</option>
                            <option value={4}>April</option>
                            <option value={5}>Mai</option>
                            <option value={6}>Juni</option>
                            <option value={7}>Juli</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>Oktober</option>
                            <option value={11}>November</option>
                            <option value={12}>Dezember</option>
                        </Form.Select>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-4 border-bottom pb-5 border-top pt-4">
                <Row className="">
                    <Col xs={12} className={`mb-3 ${!isMobile ? 'ps-0' : ''}`}>
                        <h3>Buchhaltung</h3>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className={`pb-4 ${!isMobile ? 'ps-0' : ''}`}>
                        <UnpaidPayments startDate={startDate} endDate={endDate} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} className={`${!isMobile ? 'ps-0' : ''}`}>
                        <Stack gap={4}>
                            <PaidPaymentsNoInvoice startDate={startDate} endDate={endDate} />
                            <TaxCardUnpaid startDate={startDate} endDate={endDate} />
                        </Stack>
                    </Col>

                    <Col xs={12} md={6} className={`${!isMobile ? 'ps-0' : 'mt-4'}`}>
                        <Stack gap={4}>
                            <PaidPaymentsInvoice startDate={startDate} endDate={endDate} />
                            <TaxCard startDate={startDate} endDate={endDate} />
                        </Stack>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-4 mb-4 border-bottom pb-5">
                <Row>
                    <Col xs={12} className={`mb-3 ${!isMobile ? 'ps-0' : ''}`}>
                        <h3>Abonnements</h3>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6} className={`${!isMobile ? 'ps-0' : 'mb-3'}`}>
                        <Stack gap={4}>
                            <ActiveSubsCard startDate={startDate} endDate={endDate} />
                        </Stack>
                    </Col>

                    <Col xs={12} md={6} className={`${!isMobile ? 'pe-0' : 'mt-3'}`}>
                        <Stack gap={4}>
                            <SubIncomeCard startDate={startDate} endDate={endDate} />
                        </Stack>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-4 mb-4 border-bottom pb-5">
                <Row>
                    <Col xs={12} className={`mb-3 ${!isMobile ? 'ps-0' : ''}`} >
                        <h3>Kurse</h3>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6} className={`${!isMobile ? 'ps-0' : 'mb-3'}`}>
                        <Stack gap={4}>
                            <CourseStatsCard startDate={startDate} endDate={endDate} />
                        </Stack>
                    </Col>

                    <Col xs={12} md={6} className={`${!isMobile ? 'pe-0' : 'mt-2 mb-3'}`}>
                        <Stack gap={4}><CourseStatsCardFuture startDate={startDate} endDate={endDate} /></Stack>
                    </Col>
                </Row>
            </Container>
        </Loader>
    );
};
