import { Card, Col, Row } from "react-bootstrap";
import { Loader } from "../../../components/loader";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { formatWithDots } from "../../../utils/formatNumbers";
import { useState } from "react";
import { PaymentsTableModal } from "../modals/payment-table-modal/PaymentsTableModal";

export const UnpaidPayments = ({ startDate, endDate }) => {
    const { token } = useRecoilValue(userState);
    const [showPaymentsTableModal, setShowPaymentsTableModal] = useState(false);

    const queryClient = useQueryClient();

    const unpaidPaymentsQuery = useQuery({
        queryKey: ["unpaid-payments", startDate, endDate],
        queryFn: () => getRequest(`/auth/statistics/unpaid-payments?startDate=${startDate}&endDate=${endDate}`, token),
    });
    const { data: unpaidPayments } = unpaidPaymentsQuery;

    const refreshAllStats = async () => {
        await queryClient.invalidateQueries(['paid-payments', 'unpaid-payments']);
    };

    return (
        <Loader isActive={unpaidPaymentsQuery.isLoading || unpaidPaymentsQuery.isFetching}>
            {unpaidPayments && (
                <>
                    <Card>
                        <Card.Header className="hoverable-title" onClick={() => setShowPaymentsTableModal(true)}>
                            Offene Posten
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={6}>Offene Einnahmen:</Col>

                                <Col xs={6} className="text-end">
                                    {formatWithDots(unpaidPayments.total)} €
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <PaymentsTableModal
                        title={"Offene Posten"}
                        showPaymentsTableModal={showPaymentsTableModal}
                        setShowPaymentsTableModal={setShowPaymentsTableModal}
                        payments={unpaidPayments.payments}
                        refetch={refreshAllStats}
                    />
                </>
            )}
        </Loader>
    );
};
