import { Col, Container, Row, Stack } from "react-bootstrap"
import { Loader } from "../../../../components/loader"
import { ArrowLeft } from "react-bootstrap-icons"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { userState } from "../../../../utils/atom"
import { useQuery } from "@tanstack/react-query"
import { getRequest } from "../../../../utils/apiRequest"
import { useEffect, useState } from "react"
import { ChildDetailPerformance } from "./child-detail-components/childDetailPerformance"
import { ChildDetailBadges } from "./child-detail-components/childDetailBadges"
import { ChildDetailGeneral } from "./child-detail-components/childDetailGeneral"
import { ChildDetailPerformanceSubscription } from "./child-detail-components/childDetailPerformanceSubscription"

export const ChildDetail = () => {

    const navigate = useNavigate();
    const { id } = useParams(); 
    const { token } = useRecoilValue(userState); 

    const childQuery = useQuery({
        queryKey: ["child-by-id-admin"],
        queryFn: () => getRequest(`/auth/child/${id}`, token),
    });
    const { data } = childQuery;

    return (
        <Loader isActive={childQuery.isLoading || childQuery.isFetching}>
            {data &&
                <Container fluid>
                    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                        <Col xs={2} className="p-0">
                            <ArrowLeft
                                size={24}
                                onClick={() => navigate(-1)}
                                style={{ cursor: "pointer" }}
                            />
                        </Col>
                        <Col
                            xs={8}
                            className="text-center d-flex align-items-center justify-content-center"
                        >
                            <h5 className="m-0">
                                {data.firstName ? data.firstName : 'Fehler'} {data.lastName ? data.lastName : ''}
                            </h5>
                        </Col>
                        <Col xs={2}></Col>
                    </Row>

                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <Stack>
                                    <ChildDetailGeneral child={data} />
                                </Stack>
                            </Col>

                            <Col xs={12} md={6}>
                                <Stack>     
                                    <ChildDetailPerformance 
                                        child={data} 
                                        token={token} 
                                        refetch={childQuery.refetch}
                                    />

                                    <ChildDetailBadges 
                                        child={data} 
                                        token={token}
                                        refetch={childQuery.refetch}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
        </Loader>
    )
}