import { Card, Col, Row } from "react-bootstrap";
import { Loader } from "../../../components/loader";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { formatWithDots } from "../../../utils/formatNumbers";
import { PaymentsTableModal } from "../modals/payment-table-modal/PaymentsTableModal";
import { useState } from "react";

export const PaidPaymentsNoInvoice = ({ startDate, endDate }) => {

    const { token } = useRecoilValue(userState);
    const [showPaymentsTableModal, setShowPaymentsTableModal] = useState(false);

    const queryClient = useQueryClient();

    const paidPaymentsQuery = useQuery({
        queryKey: ["paid-payments", startDate, endDate],
        queryFn: () => getRequest(`/auth/statistics/paid-payments?startDate=${startDate}&endDate=${endDate}`, token),
        refetchOnWindowFocus: false,
    });
    const { data: paidPayments } = paidPaymentsQuery;

    const refreshAllStats = async () => {
        await queryClient.invalidateQueries(['paid-payments', 'unpaid-payments']);
    };

    return (
        <Loader isActive={paidPaymentsQuery.isLoading || paidPaymentsQuery.isFetching}>
            {paidPayments &&
                <>
                    <Card>
                        <Card.Header 
                            className="hoverable-title"
                            onClick={() => setShowPaymentsTableModal(true)}
                        >
                            Nicht verbuchte Einnahmen
                        </Card.Header>
                        <Card.Body>
                            <Row className="pb-3">
                                <Col xs={6}>
                                    Bar:
                                </Col>

                                <Col xs={6} className="text-end">
                                    {formatWithDots(paidPayments.total.cash)} €
                                </Col>
                            </Row>

                            <Row className="pt-3 pb-3 border-top border-bottom">
                                <Col xs={6}>
                                    PayPal:
                                </Col>

                                <Col xs={6} className="text-end">
                                    {formatWithDots(paidPayments.total.paypal)} €
                                </Col>
                            </Row>

                            <Row className="pt-3 pb-3 border-bottom">
                                <Col xs={6}>
                                    Überweisung:
                                </Col>

                                <Col xs={6} className="text-end">
                                    {formatWithDots(paidPayments.total.banktransfer)} €
                                </Col>
                            </Row>

                            <Row className="pt-3">
                                <Col xs={6}>
                                    Kreditkarte:
                                </Col>

                                <Col xs={6} className="text-end">
                                    {formatWithDots(paidPayments.total.card)} €
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Row className="pt-3 pb-3">
                                <Col xs={6}>
                                    Summe:
                                </Col>
                                <Col xs={6} className="text-end">
                                    {formatWithDots(paidPayments.total.total)} €
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>

                    <PaymentsTableModal
                        title={'Nicht verbuchte Einnahmen'}
                        showPaymentsTableModal={showPaymentsTableModal}
                        setShowPaymentsTableModal={setShowPaymentsTableModal}
                        payments={paidPayments.payments}
                        refetch={refreshAllStats}
                    />
                </>
            }
        </Loader>
    );
};
