import { Card, Col, Row } from "react-bootstrap";
import { Loader } from "../../../components/loader";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";
import { formatWithDots } from "../../../utils/formatNumbers";

export const TaxCard = ({ startDate, endDate }) => {

    const { token } = useRecoilValue(userState);

    const taxQuery = useQuery({
        queryKey: ["tax-query", startDate, endDate],
        queryFn: () => getRequest(`/auth/statistics/taxes?startDate=${startDate}&endDate=${endDate}`, token),
        refetchOnWindowFocus: false,
    });
    const { data: taxes } = taxQuery;

    const feeQuery = useQuery({
        queryKey: ["fee-query", startDate, endDate],
        queryFn: () => getRequest(`/auth/statistics/transaction-fee-paid?startDate=${startDate}&endDate=${endDate}`, token),
        refetchOnWindowFocus: false,
    });

    const { data: fees } = feeQuery;

    return (
        <Loader isActive={taxQuery.isLoading || taxQuery.isFetching}>
            <Card>
                <Card.Header>Vereinnahmte Umsatzsteuer & Gebühren</Card.Header>
                <Card.Body className="p-0 overflow-hidden">
                    <Row className="p-3 border-bottom">
                        <Col xs={6}>
                            Vereinnahmte Umsatzsteuer:
                        </Col>
                        <Col xs={6} className="text-end">
                            {formatWithDots(taxes)} €
                        </Col>
                    </Row>
                    <Row className="p-3">
                        <Col xs={6}>
                            Enthaltene Transaktionsgebühren:
                        </Col>
                        <Col xs={6} className="text-end">
                            {formatWithDots(fees)} €
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Loader>
    );
};
