import { Card, Col, Row } from "react-bootstrap"
import { Loader } from "../../../components/loader"
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";

export const CourseStatsCard = ({ startDate, endDate }) => {

    const { token } = useRecoilValue(userState);

    const courseAmountQuery = useQuery({
        queryKey: ["course-amount", startDate, endDate],
        queryFn: () => getRequest(`/auth/statistics/course-amount?startDate=${startDate}&endDate=${endDate}`, token),
        refetchOnWindowFocus: false,
    });
    const { data: courseAmount } = courseAmountQuery;

    return (
        <Loader isActive={courseAmountQuery.isLoading || courseAmountQuery.isFetching}>
            {courseAmount &&
                <Card>
                    <Card.Header>Stattgefundene Kurse</Card.Header>
                    <Card.Body className="pt-0 pb-0 no-border-on-last-child">
                        {Object.entries(courseAmount).filter(([key]) => key !== 'total').map(([title, count]) => (
                            <Row key={title} className="border-bottom p-3">
                                <Col xs={6}>
                                    {title}:
                                </Col>
                                <Col xs={6} className="text-end">
                                    {count}
                                </Col>
                            </Row>
                        ))}
                    </Card.Body>
                    <Card.Footer>
                        <Row className="p-3">
                            <Col xs={6}>
                                Gesamt:
                            </Col>
                            <Col xs={6} className="text-end">
                                {courseAmount.total}
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            }
        </Loader>
    )
}