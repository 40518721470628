import { useState } from "react";
import { Button, Col, Form, Nav, Offcanvas, Row } from "react-bootstrap"
import { ArrowRepeat, Calendar2DateFill, Cash, ClockFill, FileEarmarkPlusFill, GraphUpArrow, HouseFill, List, PeopleFill, PersonFillAdd } from 'react-bootstrap-icons';
import { removeLocalStorage } from "../utils/localStorage";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../utils/atom";
import { useNavigate } from "react-router-dom";

export const OffcanvasNavigation = () => {
    
    const setUserState = useSetRecoilState(userState);
    const user = useRecoilValue(userState);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isLightMode, setIsLightMode] = useState(document.querySelector('[data-bs-theme').getAttribute('data-bs-theme') === 'light');

    const handleSwitchChange = (event) => {
        const doc = document.querySelector('[data-bs-theme]');
        if (event.target.checked) {
            doc.setAttribute('data-bs-theme', 'light');
            setIsLightMode(true);
        }
        else {
            doc.setAttribute('data-bs-theme', 'dark')
            setIsLightMode(false);
        }
    }

    const logout = () => {
        removeLocalStorage('token');
        setUserState(null);
    }
    
    return (
        <>
            <List onClick={handleShow} cursor={'pointer'} size={32}/>
            <Offcanvas 
                show={show} 
                onHide={handleClose} 
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Navigation</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    {user.isAdmin
                    ?
                        <Nav>
                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <HouseFill />
                                        </Col>
                                        <Col xs={11} className=" ps-3">
                                            Start
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/all-courses'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <Calendar2DateFill />
                                        </Col>
                                        <Col xs={11} className="ps-3">
                                            Kursübersicht
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/past-courses'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <Calendar2DateFill />
                                        </Col>
                                        <Col xs={11} className="ps-3">
                                            Vergangene Kurse
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/payments'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <Cash />
                                        </Col>
                                        <Col xs={11} className="ps-3">
                                            Zahlungen
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/invoice'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <FileEarmarkPlusFill />
                                        </Col>
                                        <Col xs={11} className="ps-3">
                                            Rechnungen
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/statistics'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <GraphUpArrow />
                                        </Col>
                                        <Col xs={11} className="ps-3">
                                            Statistik
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/all-users'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <PeopleFill />
                                        </Col>
                                        <Col xs={11} className="ps-3">
                                            Benutzerverwaltung
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/subscriptions'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <ArrowRepeat />
                                        </Col>
                                        <Col xs={11} className="ps-3">
                                            Aboverwaltung
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            {/* <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/all-courses'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <GearFill />
                                        </Col>
                                        <Col xs={11} className=" ps-3">
                                            Einstellungen
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item> */}
                        </Nav>
                    :
                        <Nav>
                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <HouseFill />
                                        </Col>
                                        <Col xs={11} className=" ps-3">
                                            Start
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/all-courses'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <Calendar2DateFill />
                                        </Col>
                                        <Col xs={11} className=" ps-3">
                                            Kurse
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/profile'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <PeopleFill />
                                        </Col>
                                        <Col xs={11} className=" ps-3">
                                            Profil
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>
                            
                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/subscriptions'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <ArrowRepeat />
                                        </Col>
                                        <Col xs={11} className=" ps-3">
                                            Meine Abonnements
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/waiting-rooms'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <ClockFill />
                                        </Col>
                                        <Col xs={11} className=" ps-3">
                                            Meine Wartelisten
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="w-100">
                                <Nav.Link 
                                    className="p-3 border-0 border-bottom"
                                    onClick={() => {navigate('/add-children'); setShow(false)}}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <PersonFillAdd />
                                        </Col>
                                        <Col xs={11} className=" ps-3">
                                            Teilnehmer hinzufügen
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    }

                    {/* <Row>
                        <Col>
                            <Form className="mt-3">
                                <Form.Check
                                    type="switch"
                                    label="Light-Mode einschalten (Beta)"
                                    checked={isLightMode}
                                    onChange={handleSwitchChange}
                                >
                                </Form.Check>
                            </Form>
                        </Col>
                    </Row> */}
                    <Button className="mt-3" onClick={() => logout()}>Abmelden</Button>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}