import { Button, Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import { Loader } from "../loader"
import { getRequest } from "../../utils/apiRequest"
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import '../../styles/card-list/card-list.scss'
import { formatWithDots } from "../../utils/formatNumbers";


export const UpcomingEarnings = () => {

    const { token } = useRecoilValue(userState);

    const upcomingEarnings = useQuery({
        queryKey: ['upcoming-earnings'],
        queryFn: () => getRequest('/auth/upcoming-earnings', token)
    });
    const { data } = upcomingEarnings;

    return (
        <Loader isActive={upcomingEarnings.isFetching || upcomingEarnings.isLoading}>
            {data &&
                <Card className="mt-3">
                    <Card.Header className="p-3">
                        <h5 className="m-0">Anstehende Einnahmen</h5>
                    </Card.Header>
                    <Card.Body className="p-0">
                        {Object.keys(data).length > 0
                        ?
                            <ListGroup className="p-0">
                                {Object.keys(data).map(month => {
                                    return <ListGroupItem key={month} className="p-3 border-0 border-bottom">
                                        <Row>
                                            <Col>
                                                {month}
                                            </Col>
                                            <Col className="text-end">
                                                {formatWithDots(data[month])} €
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                })}
                                <ListGroupItem className="p-3 border-0">
                                    <Row>
                                        <Col className="">
                                            Gesamt:
                                        </Col>
                                        <Col className="text-end">
                                            {formatWithDots( Object.values(data).reduce((acc, value) => {
                                                return acc += value
                                            }))} €
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        
                        :
                            <p className="m-0 p-3">Keine anstehenden Einnahmen. ☹️</p>
                        }
                    </Card.Body>
                </Card>
            }
        </Loader>
    )
}