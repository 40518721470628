export const formatDateTimeToDDMMYY = (datetimeString) => {
    const date = new Date(datetimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
  
    return `${day}.${month}.${year}`;
}

export const formatDateTimeToDDMMYYYY = (datetimeString) => {
    const date = new Date(datetimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
  
    return `${day}.${month}.${year}`;
}

export const formatDateTimeToYYYYMMDD = (datetimeString) => {
    const date = new Date(datetimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
  
    return `${year}-${month}-${day}`;
}

export const formatDateTimeToHHMM = (datetimeString) => {
    const date = new Date(datetimeString);
    const localHours = date.getUTCHours();
    const localMinutes = date.getUTCMinutes();
    const hours = (localHours < 10 ? "0" : "") + localHours;
    const minutes = (localMinutes < 10 ? "0" : "") + localMinutes;

    return hours + ":" + minutes;
}

export const formatDateAndTimeToISO = (date, time = '00:00') => {
    const combinedStr = `${date}T${time}:00`;
    let localDate = new Date(combinedStr);
    localDate = new Date(localDate.getTime() - (localDate.getTimezoneOffset() * 60000));
    
    return localDate.toISOString().replace('.000Z', 'Z');
}

export const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const quarterMap = {
    1: {
        start: '01-01',
        end: '03-31'
    },

    2: {
        start: '04-01',
        end: '06-30'
    },

    3: {
        start: '07-01',
        end: '09-30'
    },

    4: {
        start: '10-01',
        end: '12-31'
    }
};

export const monthMap = {
    0: {
        start: '01-01',
        end: '12-31'
    },
    1: {
        start: '01-01',
        end: '01-31'
    },
    2: {
        start: '02-01',
        end: '02-29'
    },
    3: {
        start: '03-01',
        end: '03-31'
    },
    4: {
        start: '04-01',
        end: '04-30'
    },
    5: {
        start: '05-01',
        end: '05-31'
    },
    6: {
        start: '06-01',
        end: '06-30'
    },
    7: {
        start: '07-01',
        end: '07-31'
    },
    8: {
        start: '08-01',
        end: '08-31'
    },
    9: {
        start: '09-01',
        end: '09-30'
    },
    10: {
        start: '10-01',
        end: '10-31'
    },
    11: {
        start: '11-01',
        end: '11-30'
    },
    12: {
        start: '12-01',
        end: '12-31'
    }
};

export const getMonthMap = (year) => ({
    1: { start: '01-01', end: '01-31' },
    2: { start: '02-01', end: isLeapYear(year) ? '02-29' : '02-28' },
    3: { start: '03-01', end: '03-31' },
    4: { start: '04-01', end: '04-30' },
    5: { start: '05-01', end: '05-31' },
    6: { start: '06-01', end: '06-30' },
    7: { start: '07-01', end: '07-31' },
    8: { start: '08-01', end: '08-31' },
    9: { start: '09-01', end: '09-30' },
    10: { start: '10-01', end: '10-31' },
    11: { start: '11-01', end: '11-30' },
    12: { start: '12-01', end: '12-31' },
});

const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};