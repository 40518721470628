import { Card, Col, Row } from "react-bootstrap";
import { Loader } from "../../../components/loader";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";
import { formatWithDots } from "../../../utils/formatNumbers";
import { InfoCircle } from "react-bootstrap-icons";

export const TaxCardUnpaid = ({ startDate, endDate }) => {

    const { token } = useRecoilValue(userState);

    const taxQuery = useQuery({
        queryKey: ["estimated-tax-query", startDate, endDate],
        queryFn: () => getRequest(`/auth/statistics/estimated-taxes?startDate=${startDate}&endDate=${endDate}`, token),
        refetchOnWindowFocus: false,
    });
    const { data: taxes } = taxQuery;

    const feeQuery = useQuery({
        queryKey: ["fee-query-unpaid", startDate, endDate],
        queryFn: () => getRequest(`/auth/statistics/transaction-fee-unpaid?startDate=${startDate}&endDate=${endDate}`, token),
        refetchOnWindowFocus: false,
    });

    const { data: fees } = feeQuery;

    return (
        <Loader isActive={taxQuery.isLoading || taxQuery.isFetching}>
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={10}>
                            Geschätzte Umsatzsteuer & Gebühren
                        </Col>
                        <Col xs={2} className="text-end">
                            {/* <InfoCircle /> */}
                        </Col>
                    </Row>

                </Card.Header>
                <Card.Body className="p-0 overflow-hidden">
                    <Row className="p-3 border-bottom">
                        <Col xs={6}>
                            Erwartete Umsatzsteuer:
                        </Col>
                        <Col xs={6} className="text-end">
                            {formatWithDots(taxes)} €
                        </Col>
                    </Row>
                    <Row className="p-3">
                        <Col xs={6}>
                            Enthaltene Transaktionsgebühren:
                        </Col>
                        <Col xs={6} className="text-end">
                            {formatWithDots(fees)} €
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Loader>
    );
};
