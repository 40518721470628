import { Card, Col, Row } from "react-bootstrap"
import { Loader } from "../../../../components/loader"
import { useRecoilValue } from "recoil";
import { userState } from "../../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../../utils/apiRequest";

export const ActiveSubsCard = ({ startDate, endDate }) => {

    const { token } = useRecoilValue(userState);

    const activeSubsQuery = useQuery({
        queryKey: ["active-subs", startDate, endDate],
        queryFn: () => getRequest(`/auth/statistics/active-subs?startDate=${startDate}&endDate=${endDate}`, token),
        refetchOnWindowFocus: false,
    });
    const { data: activeSubs } = activeSubsQuery;

    return (
        <Loader isActive={activeSubsQuery.isLoading || activeSubsQuery.isFetching}>
            {activeSubs &&
                <Card>
                    <Card.Header>Aktive Abonnements</Card.Header>
                    <Card.Body className="pt-0 pb-0 no-border-on-last-child">
                        {Object.entries(activeSubs).filter(([key]) => key !== 'total').map(([title, count]) => (
                            <Row key={title} className="border-bottom p-3">
                                <Col xs={6}>
                                    {title}:
                                </Col>
                                <Col xs={6} className="text-end">
                                    {count}
                                </Col>
                            </Row>
                        ))}
                    </Card.Body>
                    <Card.Footer>
                        <Row className="p-3">
                            <Col xs={6}>
                                Gesamt:
                            </Col>
                            <Col xs={6} className="text-end">
                                {activeSubs.total}
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            }
        </Loader>
    )
}