import { Card, Col, Row } from "react-bootstrap";
import { Loader } from "../../../../../components/loader";
import { useRecoilValue } from "recoil";
import { userState } from "../../../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../../../utils/apiRequest";
import { formatWithDots } from "../../../../../utils/formatNumbers";

export const SubIncomeCard = ({ startDate, endDate }) => {

    const { token } = useRecoilValue(userState);

    const subIncomeQuery = useQuery({
        queryKey: ["sub-income-total", startDate, endDate],
        queryFn: () => getRequest(`/auth/statistics/sub-income-total?startDate=${startDate}&endDate=${endDate}`, token),
        refetchOnWindowFocus: false,
    });
    const { data: subIncome } = subIncomeQuery;

    return (
        <Loader isActive={false}>
            {subIncome &&
                <Card>
                    <Card.Header>Abonnement-Einnahmen</Card.Header>
                    <Card.Body className="p-0 overflow-hidden">
                        <Row className="border-bottom p-3">
                            <Col xs={6}>
                                Monatliche Einnahmen:
                            </Col>
                            <Col xs={6} className="text-end">
                                {formatWithDots(subIncome.monthlyIncome)} €
                            </Col>
                        </Row>
                        <Row className="p-3">
                            <Col xs={6}>
                                Jährliche Einnahmen:
                            </Col>
                            <Col xs={6} className="text-end">
                                {formatWithDots(subIncome.yearlyIncome)} €
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
        </Loader>
    );
};
