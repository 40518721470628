import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import { formatDateAndTimeToISO, formatDateTimeToDDMMYY } from "../../../utils/formatDateTime";

export const PdfDocument = ({ pdfData }) => {
    const positionsPerPage = 10;
    const numPages = Math.ceil(pdfData.positions.length / positionsPerPage);

    const styles = StyleSheet.create({
        page: {},
        section: {
            flexDirection: "row",
            marginBottom: "10mm",
        },
        table: {
            display: "table",
            width: "100%",
            marginTop: "5mm",
            paddingLeft: "20mm",
            paddingRight: "10mm",
        },
        tableRow: {
            flexDirection: "row",
        },
        tableColHeader: {
            width: "20%",
            backgroundColor: "black",
            color: "white",
            padding: 5,
            fontSize: "10px",
        },
        tableCol: {
            width: "20%",
            borderStyle: "solid",
            borderBottomWidth: 1,
            borderRightWidth: 1,
            padding: 5,
            fontSize: "10px",
            textAlign: "center",
        },
        tableHeaderText: {
            fontWeight: "bold",
        },
        title: {
            paddingTop: "25mm",
            paddingLeft: "20mm",
            fontSize: "12px",
            marginBottom: "5mm",
        },
        content: {
            flexGrow: 1,
        },
        footerContainer: {
            backgroundColor: "#f5f5f5",
            paddingLeft: "20mm",
            paddingRight: "10mm",
            paddingTop: "5mm",
            paddingBottom: "5mm",
            marginTop: "10mm",
            borderTop: "1px solid #e0e0e0",
            fontSize: "8px",
        },
        footerTitle: {
            fontSize: "10px",
            fontWeight: "bold",
            marginBottom: "5px",
        },
        footerRow: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "5px",
        },
        footerLabel: {
            fontWeight: "bold",
            width: "30%",
        },
        footerValue: {
            width: "65%",
        },
        footerBottomText: {
            marginTop: "10px",
            fontSize: "8px",
            textAlign: "start",
        },
    });

    // const totalNetto = pdfData.positions.reduce((total, position) => {
    //     const costValue = parseFloat(position.amount)
    //     return total + costValue;
    // }, 0) / (1 + (pdfData.taxRate / 100));

    // const totalMwSt = (totalNetto * (pdfData.taxRate / (100 + pdfData.taxRate))).toFixed(2);
    // const totalAmount = (totalNetto + parseFloat(totalMwSt)).toFixed(2);

    const totalBrutto = pdfData.positions.reduce((total, position) => {
        const costValue = parseFloat(position.amount.replace(',', '.'));
        return total + costValue;
    }, 0);

    const totalNetto = totalBrutto / (1 + pdfData.taxRate / 100);
    const totalMwSt = (totalBrutto - totalNetto).toFixed(2);

    return (
        <Document>
            {Array.from({ length: numPages }, (_, pageIndex) => (
                <Page size="A4" style={styles.page} key={pageIndex}>
                    {/* Header Section */}
                    <View style={styles.section}>
                        {/* TOP LEFT */}
                        <View style={{ paddingTop: "45mm", paddingLeft: "20mm", width: "85mm" }}>
                            <Text style={{ fontSize: "8px" }}>
                                René Schmidt | Kiddiefy, Ovelgönner Weg 31, 21335 Lüneburg
                            </Text>
                            <Text style={{ fontSize: "10px", marginTop: "5mm" }}>
                                {pdfData.firstName} {pdfData.lastName}
                            </Text>
                            <Text style={{ fontSize: "10px" }}>
                                {pdfData.street} {pdfData.number}
                            </Text>
                            <Text style={{ fontSize: "10px" }}>
                                {pdfData.postalCode} {pdfData.city}
                            </Text>
                        </View>

                        {/* TOP RIGHT */}
                        <View style={{
                            width: "50%",
                            paddingTop: "5mm",
                            paddingRight: "10mm",
                            textAlign: "right",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                        }}>
                            <Image
                                src={"/logo-transparent.png"}
                                style={{
                                    width: "20mm",
                                    aspectRatio: "1",
                                }}
                            />
                            <Text style={{ marginTop: "20mm", fontSize: "10px" }}>
                                {`Rechnungsnummer: RE-${new Date().getFullYear()}-${pdfData.invoiceNumber}`}
                            </Text>
                            <Text style={{ fontSize: "10px" }}>
                                Rechnungsdatum: {formatDateTimeToDDMMYY(formatDateAndTimeToISO(pdfData.invoiceDate))}
                            </Text>
                            <Text style={{ fontSize: "10px" }}>
                                E-Mail: info@kiddiefy.de
                            </Text>
                            <Text style={{ fontSize: "10px" }}>
                                Seite {pageIndex + 1} von {numPages}
                            </Text>
                        </View>
                    </View>

                    {/* Title Section */}
                    <View>
                        <Text style={styles.title}>Rechnungspositionen</Text>
                    </View>

                    {/* Table Section */}
                    <View style={styles.table}>
                        {/* Table Header */}
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableHeaderText}>Nr.</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableHeaderText}>Kurs</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableHeaderText}>Teilnehmer</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableHeaderText}>Datum</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableHeaderText}>Betrag</Text>
                            </View>
                        </View>

                        {/* Table Rows */}
                        {pdfData.positions
                            .slice(
                                pageIndex * positionsPerPage,
                                pageIndex * positionsPerPage + positionsPerPage
                            )
                            .map((position, index) => (
                                <View style={styles.tableRow} key={index}>
                                    <View style={styles.tableCol}>
                                        <Text>{pageIndex * positionsPerPage + index + 1}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text>{position.reason}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text>
                                            {position.participant}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text>
                                            {formatDateTimeToDDMMYY(
                                                new Date(position.date)
                                            )}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text>{parseFloat(position.amount.replace(',', '.')).toFixed(2).toString().replace('.', ',')} €</Text>
                                    </View>
                                </View>
                            ))}
                    </View>

                    {/* Spacer to push footer down */}
                    <View style={styles.content}></View>

                    {/* Display total only on the last page */}
                    {pageIndex === numPages - 1 && (
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableHeaderText}>Gesamt Netto:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text>{totalNetto.toFixed(2).toString().replace('.', ',')} €</Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableHeaderText}>Enthaltene MwSt.:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text>{totalMwSt.toString().replace('.', ',')} €</Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableHeaderText}>Gesamt Brutto</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text>{totalBrutto.toFixed(2).toString().replace('.', ',')} €</Text>
                                </View>
                            </View>
                        </View>
                    )}

                    {/* Footer Section */}
                    <View style={styles.footerContainer}>
                        <Text style={styles.footerTitle}>Zahlungsdetails</Text>

                        <View style={styles.footerRow}>
                            <Text style={styles.footerLabel}>Name des/r Begünstigten</Text>
                            <Text style={styles.footerValue}>René Andreas Schmidt</Text>
                        </View>

                        <View style={styles.footerRow}>
                            <Text style={styles.footerLabel}>BIC</Text>
                            <Text style={styles.footerValue}>NOLADE21LBG</Text>
                        </View>

                        <View style={styles.footerRow}>
                            <Text style={styles.footerLabel}>IBAN</Text>
                            <Text style={styles.footerValue}>DE56 2405 0110 0066 1268 06</Text>
                        </View>

                        <View style={styles.footerRow}>
                            <Text style={styles.footerLabel}>Verwendungszweck</Text>
                            <Text style={styles.footerValue}>{`RE-${new Date().getFullYear()}-${pdfData.invoiceNumber}`}</Text>
                        </View>

                        <Text style={styles.footerBottomText}>
                            René Andreas Schmidt, Einzelunternehmer / PayPal: info@kiddiefy.de
                        </Text>
                    </View>
                </Page>
            ))}
        </Document>
    );
};
